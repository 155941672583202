<template>
  <div class="about" id="About">
    <Header />
    <!-- 公司簡介 -->
    <div class="other__narrow__banner" id="Intro">
      <img :src="'https://www.elevator.com.vn/upload/'+ item.about.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{item.about.title}}</h4>
        </div>
      </div>
    </div>
    <div class="other__ck__block about_created">
      <div class="container" v-html="item.aboutIntro.content"> 
      </div>
    </div>
    <div class="other__banner">
      <img :src="'https://www.elevator.com.vn/upload/'+ item.aboutHistory.image" alt="" />
      <div class="other__banner__title">
        <h2 v-html="item.aboutHistory.banner"></h2>
      </div>
    </div>
    <!-- 歷史沿革 -->
    <div class="about__history honwei_history" id="AboutHistory">
      <div class="container">
      <h2 class="about__history__title title__center">{{item.aboutHistory.title}}</h2>
        <!--  owl-carousel owl-theme owl-loaded -->
        <div class="owl-carousel owl-theme owl-loaded">
          <div class="item" v-for="a in item.aboutHistoryYear" :key="a.id">
            <div class="about_items_pic_wrapper">
              <div class="about__history__year">
                <span class="about__history__year__text" v-html="a.title"></span>
                <span class="about__history__year__line"></span>
              </div>
              <div class="about__history__content mb-3" v-for="b in item.aboutHistoryMonth.filter(x =>x.parent_id == a.id)" :key="b.id">
                <div class="about__history__content__month" v-html="b.title"></div>
                <div class="about__history__content__text"  v-html="b.descript">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="other__banner">
      <img   :src="'https://www.elevator.com.vn/upload/'+ item.aboutGlory.image" alt="" />
      <div class="about_banner_two_title">
        <h2  v-html="item.aboutGlory.banner"></h2>
      </div>
    </div>


    <!-- 榮耀 -->
    <div class="about_glory" id="AboutGlory">
      <div class="container"  v-html="item.aboutGlory.content">
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./../components/Header";
import carousel from "vue-owl-carousel";
import './../assets/js/owl.carousel'
import './../assets/js/main.js'
import { apiAbout } from "@/api/pages.js";

export default {
  name: "About",
  components: {
    Header,
    carousel,
  },
  data(){
    return{
      hash: '',
      item: {}
    }
  },
  created(){
    this.getData()
    this.getHashValue()
  },
  mounted() {
    setTimeout( () => {
      const target = $(`${this.hash}`).offset().top
      $(window).scrollTop(target)
    }, 100)
    setTimeout(() => {
      $(".owl-carousel").owlCarousel({
        loop: true,
        margin: 0,
        autoHeight: true,
        smartSpeed: 1000,
        nav: true,
        // autoWidth:true,
        responsive:{
          0:{
            items: 1
          },
          768:{
              items: 3
          },
        }
      });
    }, 500);
  },
  methods: {
    async getData(){
      try {
        const res = await apiAbout();
        this.item = res.data;
        console.log(this.item)
      }
      catch {
        console.log('err')
      }
    },
    getHashValue(){
      if(this.$route.hash){
        this.hash = this.$route.hash
      }else{
        this.hash = `#${this.$route.name}`
      }
    }
  }
};
</script>
<style lang="css" scoped>
  .about_items_pic_wrapper > div > img{
    display: none;
  }
</style>